<template>
  <section id="form-wizard-ads">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t('settingTabs.ads') }}</h1>
        <h5 class="text-primary">
          {{ $t('configAds') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <div>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          :title="$t('code.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("code.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("code.introd") }}
                </small>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('code.introd') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('code.sel') + ' *'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <b-row @click="showModal()">
                      <b-col md="7">
                        <b-form-input
                          id="content"
                          v-model="contentName"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('code.sel')"
                          disabled
                        />
                      </b-col>
                      <b-col md="2">
                        <b-button @click="showModal()">
                          {{
                            $t("Select")
                          }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <small class="text-danger">{{ requierdFunction(errors) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          :title="$t('code.C&C')"
          :before-change="validationFormCat"
        >
          <validation-observer
            ref="catRules"
            tag="form"
          >
            <b-overlay
              no-center
              :show="contentSelected.length > 0"
              variant="transparent"
              rounded="sm"
            >
              <template #overlay>
                <b-icon
                  icon="stopwatch"
                  variant="info"
                  scale="2"
                  shift-v="8"
                  shift-h="8"
                  class="position-absolute"
                  style="top: 0; right: 0"
                />
              </template>
              <b-card>
                <b-card-header class="head d-flex flex-row">
                  <div>
                    <b-card-title>{{ $t('Category') }}</b-card-title>
                    <h6 class="text-primary ">
                      {{ $t('catADs') }}
                    </h6>
                  </div>

                  <h3>
                    <b-badge
                      v-if="requiredField"
                      variant="danger"
                    >
                      <feather-icon
                        icon="AlertOctagonIcon"
                        size="50"
                        style="vertical-align: baseline;"
                      />
                      {{ $t('needAdd') }}
                    </b-badge>
                  </h3>
                </b-card-header>
                <b-card-body>
                  <b-overlay
                    variant="transparent"
                    :show="showCategories"
                  >
                    <b-row class="m-2">
                      <!-- people group 1 -->
                      <b-col md="6">
                        <b-row>
                          <b-col md="5">
                            <h6 class="text-primary font-weight-bold mb-2">
                              {{ $t("code.allC") }}
                              <feather-icon
                                v-show="resCategories.length > 0"
                                icon="PlusCircleIcon"
                                size="20"
                                class="text-success cursor-pointer ml-1"
                                @click="AddCat()"
                              />
                            </h6>
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchCategories"
                              minlength="3"
                              :placeholder="$t('slider.searchThreeCaracCat')"
                              @input="getCategoriesThreeCharact"
                            />
                          </b-col>
                        </b-row>

                        <b-form-select
                          v-model="checkResults"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush  mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="c in resCategories"
                            :key="c.node.id"
                            tag="li"
                            :value="c"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ c.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                          <b-form-select-option
                            v-if="resCategories.length == 0"
                            :value="null"
                            disabled
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div
                                v-if="searchCategories.length <= 2"
                                class="ml-25 "
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ threeCharSearch }}
                                </b-card-text>
                              </div>
                              <div
                                v-if="searchCategories.length >= 3"
                                class="text-left mt-2 ml-2"
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ $t('subscriptions.notFound') }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <!-- people group 2 -->
                      <b-col md="6">
                        <b-row>
                          <b-col
                            md="5"
                            class="d-flex align-items-center"
                          >
                            <h6 class="text-primary font-weight-bold mb-0 mt-0">
                              {{ $t('subscriptions.selectedCategory') }}
                            </h6>
                            <feather-icon
                              v-show="resultCategorySelected.length > 0"
                              icon="XCircleIcon"
                              size="20"
                              class="text-danger cursor-pointer ml-1"
                              @click="QuitarCat()"
                            />
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchCatSelected"
                              :placeholder="$t('slider.searchThreeCaracCat')"
                              @input="getCategoriesSelected"
                            />
                          </b-col>
                        </b-row>
                        <b-form-select
                          v-model="checkRemove"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="catSus in resultCategorySelected"
                            :key="catSus.node.id"
                            tag="li"
                            :value="catSus"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ catSus.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card-body>
              </b-card>
            </b-overlay>
            <b-overlay
              no-center
              :show="categorySelected.length > 0"
              variant="transparent"
              rounded="sm"
            >
              <template #overlay>
                <b-icon
                  icon="stopwatch"
                  variant="info"
                  scale="2"
                  shift-v="8"
                  shift-h="8"
                  class="position-absolute"
                  style="top: 0; right: 0"
                />
              </template>
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t('Contents') }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('contAds') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-overlay
                    variant="transparent"
                    :show="showContents"
                  >
                    <b-row class="m-2">
                      <!-- people group 1 -->
                      <b-col md="6">
                        <b-row>
                          <b-col md="5">
                            <h6 class="text-primary font-weight-bold mb-2">
                              {{ $t("code.allCon") }}

                              <feather-icon
                                v-show="resContents.length > 0"
                                icon="PlusCircleIcon"
                                size="20"
                                class="text-success cursor-pointer ml-1"
                                @click="AddCont()"
                              />
                            </h6>
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchContents"
                              minlength="3"
                              :placeholder="$t('slider.searchThreeCaracContent')"
                              @input="getContentsThreeCharact"
                            />
                          </b-col>
                        </b-row>

                        <b-form-select
                          v-model="checkContResults"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush  mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="c in resContents"
                            :key="c.node.id"
                            tag="li"
                            :value="c"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ c.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                          <b-form-select-option
                            v-if="resContents.length == 0"
                            :value="null"
                            disabled
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div
                                v-if="searchContents.length <= 2"
                                class="ml-25 "
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ threeCharSearch }}
                                </b-card-text>
                              </div>
                              <div
                                v-if="searchContents.length >= 3"
                                class="text-left mt-2 ml-2"
                              >
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ $t('subscriptions.notFound') }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <!-- people group 2 -->
                      <b-col md="6">
                        <b-row>
                          <b-col
                            md="5"
                            class="d-flex align-items-center"
                          >
                            <h6 class="text-primary font-weight-bold mb-0 mt-0">
                              {{ $t('selcCont') }}
                            </h6>
                            <feather-icon
                              v-show="resultContentsSelected.length > 0"
                              icon="XCircleIcon"
                              size="20"
                              class="text-danger cursor-pointer ml-1"
                              @click="QuitarCont()"
                            />
                          </b-col>
                          <b-col md="5">
                            <b-form-input
                              v-model="searchContSelected"
                              :placeholder="$t('slider.searchThreeCaracContent')"
                              @input="getContentsSelected"
                            />
                          </b-col>
                        </b-row>
                        <b-form-select
                          v-model="checkContRemove"
                          tag="ul"
                          multiple
                          :select-size="5"
                          class="scroll-list list-group list-group-flush mt-2 border-0"
                        >
                          <b-form-select-option
                            v-for="cont in resultContentsSelected"
                            :key="cont.node.id"
                            tag="li"
                            :value="cont"
                            class="list-group-item"
                          >
                            <div class="d-flex ">
                              <div class="ml-25 ">
                                <b-card-text class="mb-0 font-weight-bold">
                                  {{ cont.node.name }}
                                </b-card-text>
                              </div>
                            </div>
                          </b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card-body>
              </b-card>
            </b-overlay>
          </validation-observer>
        </tab-content>
        <tab-content
          title="Configuración"
          :before-change="validationFormConfig"
        >
          <validation-observer
            ref="ConfigRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("code.adsConf") }}
                </h5>
                <small class="text-muted">
                  {{ $t("code.conf") }}
                </small>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('code.impresiones')">
                  <validation-provider
                    v-slot="validationContext"
                    name="prints"
                    rules="required"
                  >
                    <b-form-input
                      id="prints"
                      v-model="prints"
                      type="number"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('code.nVis')"
                    />
                    <small class="text-primary">{{ $t("code.nVis") }}</small> <br>
                    <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('code.seg')">
                  <validation-provider
                    v-slot="validationContext"
                    name="timeout"
                    rules="required"
                  >
                    <b-form-input
                      id="timeout"
                      v-model="timeout"
                      type="number"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('code.seg2')"
                    />
                    <small class="text-primary">{{ $t("code.seg2") }}</small><br>
                    <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('code.momento')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="type"
                    rules="required"
                  >
                    <b-form-select
                      id="type"
                      v-model="type"
                      :state="errors.length > 0 ? false : null"
                      :options="typeOptions"
                    />
                    <small class="text-primary">
                      {{ $t("code.momento2") }}</small><br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('contents.selectContent')"
    >
      <div class="d-block text-center">
        <content-selector
          :sponsor="isSponsor"
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  showToast, messageError, inputDecimalNumber, utf8ToB64, isUserInGroup,
} from '@/store/functions'

import {
  BFormSelectOption,
  BFormSelect,
  BFormTextarea,
  BCard,
  BRow,
  BIcon,
  BCol,
  BBadge,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BOverlay,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

import { getUserData } from '@/auth/utils'

import * as constants from '@core/utils/constants'
import ContentSelector from '@/views/common/ContentSelector.vue'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCardBody,
    ContentSelector,
    BFormSelectOption,
    BBadge,
    BCard,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BIcon,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BCardText,
  },
  data() {
    return {
      userData: getUserData(),
      inputDecimalNumber,
      required,
      contentName: null,
      contentId: null,
      type: 'PRE',
      prints: null,
      isSponsor: false,
      timeout: null,
      categoryIdSelected: [],
      contentIdSelected: [],
      description: '',
      name: null,
      show: false,
      typeOptions: [
        { value: 'PRE', text: 'Preroll' },
        { value: 'MID', text: 'Midroll' },
        { value: 'POS', text: 'Postroll' },
      ],
      first: true,

      categorySelected: [],
      contentSelected: [],
      allCats: [],
      allConts: [],
      checkResults: [],
      checkRemove: [],
      checkContResults: [],
      checkContRemove: [],
      resultContentsSelected: [],
      resultCategorySelected: [],
      resCategories: [],
      resContents: [],
      threeCharSearch: this.$t('slider.searchThreeCaracDefault'),
      showContents: false,
      showCategories: false,
      searchCategories: '',
      searchContents: '',
      searchContSelected: '',
      searchCatSelected: '',
      requiredField: false,

    }
  },
  watch: {
    resCategories() {
      if (this.resCategories.length !== 0) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    resContents() {
      if (this.resContents.length !== 0) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchContents(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchCategories(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    categorySelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    contentSelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    timeout() {
      if (this.timeout.match(/\d+$/) === null) this.timeout = ''
    },
    prints() {
      if (this.prints.match(/^\d+$/) === null) this.prints = ''
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
  },
  methods: {
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    requierdFunction(data) {
      if (data.length !== 0) {
        return this.$t('required')
      }
      return ''
    },

    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    formSubmitted() {
      this.show = true
      axios
        .post('', {
          query: `
          mutation{
            createAds(input:{
              name:"${this.name}",
              client:"${this.userData.profile.client.id}",
              description:"${this.description}",
              campaing:"${this.$route.params.campaign}",
              isActive:true,
              prints: ${this.prints},
              timeout: ${this.timeout},
              type: ${this.type},
              content: "${this.contentId}",
              ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}",`}
              ${this.categorySelected.length !== 0
    ? `categories: [${this.getIdArray(this.categorySelected)}],`
    : ' '
}
              ${this.contentSelected.length !== 0
    ? `contents: [${this.getIdArray(this.contentSelected)}],`
    : ' '
}
              }){
           ad
            {

              name
            }
          }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.creado'), 1, this)
          this.show = false

          this.routeToEditView()
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.errorCre'), 2, this)
          this.show = false
        })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormConfig() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfigRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    SelectContentId(data) {
      this.contentId = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.contentName = data
      this.hideModal()
    },

    getContents() {
      this.showContents = true
      axios
        .post('', {
          query: `
           query{
            contentsUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchContents}"
            ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}      
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arrayTotal = response.data.data.contentsUnlimited.edges
          const arrayselec = []

          for (let i = 0; i < arrayTotal.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.contentSelected.length; j += 1) {
              if (this.contentSelected[j].node.id === arrayTotal[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              arrayselec.push(arrayTotal[i])
            }
          }
          this.resContents = arrayselec.slice()
          this.allConts = arrayselec.slice()
          this.showContents = false
        })
    },
    getCategories() {
      this.showCategories = true
      axios
        .post('', {
          query: `
           query{
            categoriesUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"                     
            ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}  
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.categoriesUnlimited.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }

          this.resCategories = array.slice()
          this.allCats = array.slice()

          this.showCategories = false
        })
    },
    searchByName() {
      this.resCategories = this.allCats.filter(item => item.node.name.toLowerCase().includes(this.searchCategories.toLowerCase()))
      this.resCategories.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    QuitarCont() {
      if (this.checkContRemove.length !== 0) {
        this.allConts = [...this.checkContRemove, ...this.allConts]
        this.checkContRemove.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.checkContRemove = []
        this.searchContentsByName()
        this.getContentsSelected()
      } else {
        this.allConts = [...this.resultContentsSelected, ...this.allConts]

        this.resultContentsSelected.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.resultContentsSelected = []
        this.searchContentsByName()
      }
    },
    QuitarCat() {
      if (this.checkRemove.length !== 0) {
        this.allCats = [...this.checkRemove, ...this.allCats]
        this.checkRemove.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.checkRemove = []
        this.searchByName()
        this.getCategoriesSelected()
      } else {
        this.allCats = [...this.resultCategorySelected, ...this.allCats]

        this.resultCategorySelected.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.resultCategorySelected = []
        this.searchByName()
      }
    },
    AddCat() {
      if (this.checkResults.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.checkResults]
        this.getCategoriesSelected()
        this.checkResults.forEach(element => {
          const idx = this.resCategories.indexOf(element)
          if (idx !== -1) {
            this.resCategories.splice(idx, 1)
          }
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.checkResults = []
        this.searchByName()
      } else {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]
        this.getCategoriesSelected()
        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.resCategories = []
        this.searchByName()
      }
    },
    getCategoriesSelected() {
      this.resultCategorySelected = this.categorySelected.filter(item => item.node.name.toLowerCase().includes(this.searchCatSelected.toLowerCase()))
    },
    getCategoriesThreeCharact() {
      if (this.searchCategories.trim().length >= 3) {
        this.getCategories()
      }
    },
    getContentsThreeCharact() {
      if (this.searchContents.trim().length >= 3) {
        this.getContents()
      }
    },
    AddCont() {
      if (this.checkContResults.length !== 0) {
        this.contentSelected = [...this.contentSelected, ...this.checkContResults]
        this.getContentsSelected()
        this.checkContResults.forEach(element => {
          const idx = this.resContents.indexOf(element)
          if (idx !== -1) {
            this.resContents.splice(idx, 1)
          }
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.checkContResults = []
        this.searchContentsByName()
      } else {
        this.contentSelected = [...this.contentSelected, ...this.resContents]
        this.getContentsSelected()
        this.resContents.forEach(element => {
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.resContents = []
        this.searchContentsByName()
      }
    },
    getContentsSelected() {
      this.resultContentsSelected = this.contentSelected.filter(item => item.node.name.toLowerCase().includes(this.searchContSelected.toLowerCase()))
    },
    searchCategorieByName(event = null, pass = false) {
      let keycode = 0
      if (event) keycode = event.keyCode || event.which

      if (keycode === 13 || pass) {
        if (this.searchCategories.trim().length >= 3) {
          this.getCategories()
        } else {
          this.resCategories = []
          this.emptySearchCat = 'Introduzca el nombre de la categoría a buscar...'
        }
      }
    },
    searchContentsByName() {
      this.resContents = this.allConts.filter(item => item.node.name.toLowerCase().includes(this.searchContents.toLowerCase()))
      this.resContents.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    routeToEditView() {
      const { campaign } = this.$route.params
      this.$router.push({
        name: 'ads-list',
        params: { campaign },
      })
    },
    validationFormCat() {
      return new Promise((resolve, reject) => {
        this.$refs.catRules.validate().then(() => {
          if (!this.isSponsor) {
            resolve(true)
          } else if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
            this.requiredField = true
            document.scrollingElement.scrollTop = 0
            reject()
          } else {
            this.requiredField = false
            resolve(true)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#form-wizard-ads .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

#form-wizard-ads .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#form-wizard-ads .modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
#form-wizard-ads .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}
</style>
